<script>
  export let style = "";
</script>

<!--
<svg viewBox="0 0 512 512" {style}>
  <g>
    <path
      d="M512,97.248c-19.04,8.352-39.328,13.888-60.48,16.576c21.76-12.992,38.368-33.408,46.176-58.016
      c-20.288,12.096-42.688,20.64-66.56,25.408C411.872,60.704,384.416,48,354.464,48c-58.112,0-104.896,47.168-104.896,104.992
      c0,8.32,0.704,16.32,2.432,23.936c-87.264-4.256-164.48-46.08-216.352-109.792c-9.056,15.712-14.368,33.696-14.368,53.056
      c0,36.352,18.72,68.576,46.624,87.232c-16.864-0.32-33.408-5.216-47.424-12.928c0,0.32,0,0.736,0,1.152
      c0,51.008,36.384,93.376,84.096,103.136c-8.544,2.336-17.856,3.456-27.52,3.456c-6.72,0-13.504-0.384-19.872-1.792
      c13.6,41.568,52.192,72.128,98.08,73.12c-35.712,27.936-81.056,44.768-130.144,44.768c-8.608,0-16.864-0.384-25.12-1.44
      C46.496,446.88,101.6,464,161.024,464c193.152,0,298.752-160,298.752-298.688c0-4.64-0.16-9.12-0.384-13.568
      C480.224,136.96,497.728,118.496,512,97.248z" />
  </g>
</svg>
-->

<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {style}>
  <path
    d="m24 4.3008c-0.89844 0.39844-1.8008 0.69922-2.8008 0.80078 1-0.60156
    1.8008-1.6016 2.1992-2.7031-1 0.60156-2 1-3.0977
    1.2031-1-1-2.3008-1.6016-3.6992-1.6016-2.7031 0-4.9023 2.1992-4.9023 4.8984
    0 0.40234 0 0.80078 0.10156
    1.1016-4.1016-0.19922-7.6992-2.1016-10.102-5.1016-0.5 0.70312-0.69922
    1.6016-0.69922 2.5 0 1.7031 0.89844 3.2031 2.1992
    4.1016-0.80078-0.10156-1.5977-0.30078-2.1992-0.60156v0.10156c0 2.3984 1.6992
    4.3984 3.8984 4.8008-0.39844 0.097657-0.79688 0.19922-1.2969 0.19922-0.30078
    0-0.60156 0-0.90234-0.10156 0.60156 2 2.4023 3.4023 4.6016 3.4023-1.6992
    1.3008-3.8008 2.0977-6.1016 2.0977-0.39844 0-0.80078 0-1.1992-0.097657
    2.1992 1.3984 4.8008 2.1992 7.5 2.1992 9.1016 0 14-7.5
    14-14v-0.60156c1-0.69922 1.8008-1.5977 2.5-2.5977" />
</svg>
